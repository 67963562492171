.appContainer {
  width: 95%;
  max-width: 95%; /* Ensures it does not grow beyond 95% width */
  margin: 10px auto; /* Centers the container vertically */
  overflow-x: hidden; /* Prevents content from spilling over horizontally */
}

.container {
  
}

.subcontainer{
  margin-top: 0px;
}


@media screen and (max-width: 768px) {
  .appContainer{
    width: 100%;
    max-width: 100%; /* Ensures it does not grow beyond 95% width */
    margin: 0px; /* Centers the container vertically */
  }

  .subcontainer{
    margin-top: 0px;
  }
}