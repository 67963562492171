.container {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.songList {
    display: flex;
    flex-direction: column;
}

.songItem {
    display: flex;
    color: var( --link-color);
    align-items: center;
    padding: 2px 0;
    cursor: pointer;
}


/* Assuming you're adding these to ArtistsSongs.module.css */
.songItemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px; /* Adjust as needed */
    border-bottom: 1px solid var(--list-border-color); /* Optional */
}

.hits {
    flex-shrink: 0; /* Prevents the hits from shrinking */
    text-align: right;
}