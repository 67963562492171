/* TopTabs.module.css */

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  font-size:12px;
  margin-top: 10px;
  border: 5px solid  var( --header-background-color) !important;
  border-bottom: 0px !important;
  width: 100%;

}

.headerContainer {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-inline: 5px;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
  background-color: var( --header-background-color);
  border-radius: var(--border-radius-sm);

}

.title {
  font-size: 20px;
  margin-top: 0; /* Removes the top margin */
  margin-bottom: 0; 
}

.filterContainer{
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.filtersButton {
  background: none;
  cursor: pointer;
}

.linkToTop100 {
  cursor: pointer;
}

.tabListContainer {
  /*max-height: 500px;  Set a fixed maximum height */
  padding-inline: 20px;
  overflow-y: auto; 
}

.rank {
  padding-right: 5px; /* Adjust as necessary */
  text-align: center;
  flex-shrink: 0; /* Prevent this from shrinking */
  width: 30px; /* Or any specific width you prefer */
}



.tabInfoContainer {
  display: flex;
  flex-grow: 1; /* Allow this to take up remaining space */
  justify-content: space-between; /* Distribute children evenly */
  align-items: center;
  padding-left: 10px; /* Space from the rank */
}


.tabRowItem {
  display: flex;
  align-items: center; /* Ensure items are aligned vertically */
  border-bottom: 0.1px solid var(--list-border-color);
  padding: 5px 0;
}


/* Styles for the scrollbar track */
.tabListContainer::-webkit-scrollbar-track {
  border-radius: var(--border-radius-sm); /* Adjust as needed */
  margin-top: 5px;
}

/* Styles for the scrollbar handle (thumb) */
.tabListContainer::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-sm); /* Adjust as needed */
  background: var(--shiny-color); /* Thumb color */
  border: 0.1px solid #9f5252; /* Optional */
}

/* Styles for the scrollbar itself */
.tabListContainer::-webkit-scrollbar {
  width: 5px; /* Adjust the width to your preference */
}

.artist, .song, .hits {
  flex-basis: 33%; /* Give each child equal basis */
  text-align: left; /* Align text to the left */
  cursor: pointer;
}


.song {
  color: var( --link-color);
  font-weight: bold;
}

.hits {
  flex: 0 0 25%;
  text-align: right;
}

.artist {
  font-weight: bold;
}



/* Responsive design adjustments */
@media (max-width: 768px) {
  .container {
      width: 98%;
  }
}