.searchContainer {
  display: flex; /* Use flex container */
  width: 100%; /* Container takes full width of its parent */

}

.searchInputStyle {
  flex-grow: 1; /* Input will grow to take available space */
  padding: 10px 30px;
  background-color: var(--background-color-black);
  border: 1px solid var(--header-background-color);
}

.searchResultListStyle {
  list-style: none;
  padding: 0;
  margin-top: 50px;
  position: absolute;
  width: 100%; /* Same width as the search bar */
  border-right: 1px solid var(--header-background-color);
  border-left: 1px solid var(--header-background-color);
  max-height: 300px; /* Set a fixed maximum height */
  overflow-y: auto; 
  z-index: 2; 
}


.searchResultItemStyle {
    padding: 10px;
    padding-left: 32px;
    background-color: var(--background-color-black);
}

.searchResultItemStyle:hover {
    cursor: pointer;
    background-color: var(--header-background-color);
    
  }
  
.searchIconStyle{
  position: absolute;
  padding-left: 11px;
  padding-top : 12px;
}
