/* ChordDiagram.module.css */
.chordsContainer {
  white-space: pre-wrap; /* Ensure that text wraps to the next line */
  word-wrap: break-word; /* Break long words to fit within the container */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
}

.chord {
  background-color: var(--chords-chord-background-color);
  color: var(--chords-text-color);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 1px;
  display: inline-block;
}

.lyric {
  color: var(--chords-text-color);
  display: inline;
}

.space {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  display: inline; 
}

.chord:hover, .lyric:hover {
  color: var(--chords-text-color) !important;
}
