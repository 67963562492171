.commentsSection {
    width: 100%;
    background-color: var(--chords-background-color)
  }

  
  .commentsSection h2 {
    color: #333;
    background-color: var(--chords-background-color)
  }
   