.commentForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--chords-background-color);
    border:none;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
  }
  
  .textarea {
    padding: 10px;
    border-radius: 4px;
    resize: vertical;
    margin: 0px;
    margin-bottom: 10px;
  }

  .buttonContainer{
    display: flex;
    justify-content: flex-end; 
  }
  
  .button {
    background-color: var(--link-color);
    color:var(--chords-text-color);
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }
  
  .button:hover {
    background-color: var(--hover-yellow);
    color:var(--chords-text-color);
  } 
  