.commentList {

}

.commentItem {
  background-color: #f2f2f2;
  background-color: var(--chords-background-color);
  text-align: left;
  margin-block: 15px;
}

.commentInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
}

.userName {
  display: block;
  font-weight: bold;
  color: #333;
  margin: 0px;
  font-size: 0.9em;
  
}

.userComment {
  margin: 0px;
 
}

.commentDate{
  padding-left: 10px ;
  font-weight: bold;
  color: #333;
  margin: 0px;
  font-size: 0.9em;
}
 
