.topRowContainer {
  position: relative; /* Allows absolute positioning of children relative to this container */
  display: flex;
  flex-direction: column; /* Stack children vertically by default */
  background-color: var(--chords-background-color);
  color: var(--chords-text-color);
  padding-inline: var(--spacing-l);
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--chords-background-color);
  color: var(--chords-text-color);
  padding-inline: var(--spacing-l);
  border-radius: var(--border-radius-lg);
}


.titleContainer {
  width: 100%; /* Take up full width of its parent */
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-s);
  padding: 0px;
}

.favContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 55px;
  padding-top: 10px;
}

.favSubContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--border-radius-s);
  cursor: pointer;
  padding: 0px;
  margin: 0px;  
}


.artistName, .songName {
  cursor: pointer;
  color: var(--chords-text-color);
  font-size: var(--font-size-xl);
  font-weight:500;
  border-radius: var(--border-radius-s);
}

.artistName:hover {
  background-color: var(--chords-chord-background-color);
  color: var(--chords-text-color);
}

.songName:hover {
  color: var(--chords-text-color);
}


.favoriText {
  padding-right: var(--spacing-xs);
  font-weight: bold;
  font-size: var(--font-size-s);
}


.menuContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.menuElements {
  margin:0px;
}

.chordsOfSongContainer{
  font-size: var(--font-size-l);
  width: 100%;

  border-radius: var(--border-radius-lg);
  margin-top: 10px;
  padding-block: 20px;
}

.chordsOfSongTitle{
  font-size: var(--font-size-l);
  font-weight: 600;
  color: var(--chords-text-color);
  margin-top: 0px;
  margin-bottom: 5px;
}


.menuContainer2{
  display: flex;
  align-items: stretch;
  justify-content: center;
  border: 1px solid var(--chords-chord-background-color) !important;
  border-radius: var(--border-radius-s);
  background-color: white;
  padding: 0px;
  padding-inline: 10px ;
  margin-left: 0px;
  margin-bottom: 5px;

}


.clickableElements {
  background-color: white;
  border: 0px solid var(--chords-chord-background-color) !important;
  margin-right: var(--spacing-xs);
  padding: 1px;
  cursor: pointer;
  font-size: var(--font-size-s);
  display: flex;
  align-items: center;

}
.clickableElements:hover {
  background-color: var(--chords-chord-background-color);
}


.fontSizeContainer, .transposeContainer{
  display: flex;
  flex-direction: row; 
  align-items: center;

}

.fontSizeContainerTitle:hover {
  color:var(--chords-text-color);
  font-size: var(--font-size-s);
}


.fontSizeContainerTitle{
  color:var(--chords-text-color);
  font-size: var(--font-size-s);
}

.fontSizeContainer{
  margin-right: var(--spacing-xs);
}

.transposeLabelContainer {
  position: relative; /* Establishes a positioning context for absolute children */
  display: inline-block; /* Keeps the container inline with other text/elements */
}


.transposeLevel {
  position: absolute; /* Position absolutely within the relative container */
  top: -5px; /* Position at the top of the container */
  right: -7px; /* Adjust right positioning to float to the right of the container */
  font-size: 12px; /* Smaller font size */
  color: #666; /* Optional: subdued color */
  font-weight: normal; /* Normal weight if the main text is bold */
}


.loading, .notFound {
  font-size: var(--font-size-base);
  color: var(--text-color);
}

.lyricsContainer {
  border-top: var(--box-shadow); /* Using box-shadow as a substitute for border-top */
  margin-left: 5px;
  white-space: pre-wrap; /* Ensure that text wraps to the next line */
  word-wrap: break-word; /* Break long words to fit within the container */
  overflow-wrap: break-word;
}

.lyricsContainer:hover{
  
}

.songChordsPre{
    font-family: 'Open Sans',sans-serif;
    font-weight: 400
}

.listContainer:hover, .favSubContainer:hover {
  background-color: var(--chords-chord-background-color);
}

.fontSizeButton {
  cursor: pointer;
  background-color: white;
  border: 0px solid var(--chords-chord-background-color) !important;
  color: var(--chords-text-color);
  margin: 0px;
  padding: 2px;
}

.fontSizeButton:hover {
  background-color: var(--chords-chord-background-color);
}

.ratingContainer {
  margin-top: 30px;
 
}

.ratingTitle {
  color: var(--faded-text-color);
  margin-inline: 0px;
  margin-block: 7px;
  font-size:  var(--font-size-s);
}

.commentsSectionContainer{
  margin-top: 30px;
  padding: 0px;
  width: 100%;
  margin-bottom: 5px;
}

.interactiveFeaturesContainer{
  display: flex;
  justify-content: center;
}

.visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}



/* Adjustments for larger screens */
@media screen and (min-width: 768px) {
  


  .favContainer {
    position: absolute;
    right: 0; /* Align to the right edge of the topRowContainer */
    top: 0; /* Align to the top edge to vertically align with titleContainer */
    margin-top: 0; /* Reset any margin top */
  }

  .commentsSectionContainer{
    width: 70%;
  }
  

  
}

/* Media query for mobile and smaller screens */
@media (max-width: 768px) {
  .pageContainer {
    border:none;
    padding: var(--spacing-s);
  }

  .topRowContainer {
    padding-inline: var(--spacing-s);
    padding-block: 0px;
    padding-top: 10px;
  }

  .titleContainer{
    padding-block: 0px;
    margin-block: 0px;
  }

  .songDetails {
    padding: var(--spacing-s);
  }

  .menuContainer2{
    font-size: 12px;
  }

  .favContainer{
    padding: 0px;
  }


}


