/* Header general styles */
.headerStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--background-color);
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logoStyle {
    cursor: pointer;
    font-weight: 500;
    font-size: 1.3em;
    color: var(--link-color);
    font-family: 'Helvetica' !important;
    letter-spacing: 2px;
}

.searchContainer {
    flex-grow: 1;
    margin: 0 5px;
    position: relative;
    font-size: 0.7em;
}

/* Links and icons */
.linkStyle, .mobileMenuIcon {
    cursor: pointer;
    font-size: 1em;

    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;
}

.iconStyle {
    margin-right: 5px;
}

/* Desktop links container */
.linksContainer {
    display: flex;
    gap: 10px; /* Spacing between links */
}

/* Mobile menu icon */
.mobileMenuIcon {
    display: none; /* Hidden by default, shown on mobile */
}

/* Mobile links container for pop-up */
.mobileLinksContainer {
    display: none; /* Keep it hidden by default */
    flex-direction: column;
    background-color:var( --header-background-color); /* Keep your color adjustment */
    position: fixed; /* Changed to 'fixed' to position relative to the viewport */
    top: 60px; /* Adjust this value based on the actual height of your header */
    right: 10px; /* Adjust to align the container's right edge with the menu icon */
    width: auto; /* Auto width to wrap content, or set a fixed width as needed */
    min-width: 50px; /* Minimum width to ensure readability, adjust as needed */
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Adjusted for a softer shadow */
    z-index: 1050; /* Adjusted to be higher to ensure it's above everything */
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Media query for mobile and smaller screens */
@media (max-width: 768px) {
    .headerStyle{
        padding:10px 0px;
        margin: 0px 0px;
    }

    .logoStyle{
        padding:0px 10px;
    }

    .mobileMenuIcon {
        display: block; /* Show menu icon on smaller screens */
    }

    .linksContainer {
        display: none; /* Hide desktop links on smaller screens */
    }

    .mobileLinksContainer.show {
        display: flex; /* Show when menu is open */
    }
}


/* Media query for mobile and smaller screens */
@media (min-width: 768px) {
    .searchContainer {
        margin-inline: 30px; /* Show menu icon on smaller screens */
    }
}
