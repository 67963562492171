.pageContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.genreSelectorContainer, .genreList {
  position: relative; /* Needed for absolute positioning of the child */
  padding: 0px 10px; /* Example padding, adjust as needed */

  
}

.genreButton, .genreList button {
  display: block;
  width: 100%;
  padding-block: 10px;
  margin-top: 3px;
  border-radius: 5px;
  text-align: left;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.selectedGenreButton {
  background-color: var(--link-color) ; /* Keep using !important if necessary */
  color: #000000;
  text-align: left;
}


.notSelectedGenreButton {
  color: #000000;
  text-align: left;  

  
}

.selectedGenreButton:hover, .notSelectedGenreButton:hover{
  color: #aeaeae;
}


.genreNameLi{
  list-style-type: none; /* Removes bullet points */
  padding-left: 0; /* Removes default padding */
  margin: 0px; /* Optional: Adjusts the margin for alignment purposes */
  padding: 0px; /* Adds padding to each list item for better touch targets */
  cursor: pointer; 

  
}

.genreListPopup {
  display: flex; /* Show popup when active */
  position: absolute;
  background-color: #fff;
  width: calc(100% - 20px); /* Adjust based on padding */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 10;
  flex-direction: column;
  border: 10px solid #ddd;
  border-radius: 10px;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.topSongsContainer{
  flex-grow: 1;
  margin-right: 10px;
}


.visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media screen and (max-width: 768px) {
  .pageContainer {
    flex-direction: column;
  }

  .topSongsContainer{
    margin-right: 0px;
  }

  .genreSelectorContainer {
    width: auto; /* Adjust width as needed for mobile */
    
  }

  .genreList {
    display: none; /* Hide the direct list on mobile */
    
  }

  .genreListPopup {
 
  }

  .genreButton{
    margin-top: 10px;
  }

  .notSelectedGenreButton {
    padding-left: 20px;
    width: 100%;
  }

  .selectedGenreButton {
    padding-left: 20px;
    width: 100%;
  }
}

