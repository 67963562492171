.similarArtistsContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(180px, 1fr)); /* adjust minmax for desired width */
    row-gap: 10px;
    column-gap:100px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
    text-align: left; /* Align to the left for better readability */
}

.artistItem {
    display: flex; /* Flex display for internal alignment */
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}



@media (max-width: 768px) {
    .similarArtistsContainer {
        display: flex;
        justify-content: left;
        flex-direction: column;

    }

    .artistItem{
        justify-content: left;
    }
}
