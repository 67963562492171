/* ChordDiagram.module.css */
.chordDiagram {
  display: inline-block;
  margin-right: 30px;
  margin-block: 5px;
  user-select: none;
  padding-bottom: 25px ;
}

.chordName {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 15px;
}

.fretboard {
  position: relative;
  width: 50px; /* Width of the fretboard */
  height: 80px; /* Height of the fretboard */
  background-color: white;
}

.capo {
  position: absolute;
  top: 10%; /* Adjust this value to move the capo down a bit */
  left: 5%;
  right: 5%;
  height: 4px; /* Make the capo a bit thinner */
  background-color: black;
  border-radius: 20%;
  font-size: 15px;
}

.capoLabel {
  position: absolute;
  right: -25px; /* Adjust if necessary */
  top: 0%;
  padding: 2px 4px;
  font-size: 15px;
}

.string {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: black;
}

.fret {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: black;
}

.dot {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: black;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure dots are above the capo and fret lines */
}

.fingerNumber {
  position: absolute;
  font-size: 15px;
  text-align: center;
  width: 10px; 
  bottom: 0;
  transform: translateX(-50%);
}