.artistPage {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.artistNameContainer{
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: var(--background-color);
    padding: 10px;
    border-radius: var(--border-radius-lg);
}

.artist, .song {
    cursor: pointer;
    margin: 0 5px; /* Adjust spacing around the '-' */
}
  
  .song {
    color: var( --link-color);
  }
  
.heartIcon {
    font-size: 24px; /* Adjust the size of the heart icon */
    color: red; /* This can stay inline in your JSX if preferred */
}

.artistName {
    margin-bottom: 0;
    padding: 10px;
}
