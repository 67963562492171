/* HomePage.module.css */
.pageStyle , .pageStyle2{
    display: flex;
    flex-direction: column; /* Stack vertically by default */
    width: 100%;
}



.visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Use a media query for larger screens */
@media (min-width: 768px) {
  .pageStyle, .pageStyle2 {
    flex-direction: row; /* Place items next to each other */
  }
  .pageStyle2{
    margin-left: 3px;
  }
}
