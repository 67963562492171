
.container{
  margin-top: 15px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
}

.subheader{
    margin: 0px;
}

.songContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr)); /* adjust minmax for desired width */
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

@media (max-width: 768px) {
    .songContainer {
        grid-template-columns: 1fr; /* Change to a single column layout */
    }
}

.songItem {
    display: flex; /* Flex display for internal alignment */
    flex-direction: row;
    align-items: center;
    justify-content: left;
}


.songItemText{
    color: var(--link-color);
    padding-inline: 4px ;
    line-height: 0.7;
}

