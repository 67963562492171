:root {
  --background-color-black:#000000;
  --background-color: #161616; 
  --header-background-color: #2d2d2d;
  --text-color: #ddddcc;
  --link-color: #ffc600;
  --shiny-color: #ff5d55;

  --opposite-background-color:#ddddcc;;
  --opposite-text-color:#121212;

  --chords-background-color: #f8f8f8;
  --chords-text-color:#303030;
  --chords-chord-background-color:#e1e1e1;

  --faded-text-color:rgb(111, 111, 111);

  --hover-white:#ffffff;
  --hover-yellow:#ffdf6c;

  --list-border-color:#696969;

  --faded-line-color:rgb(193, 192, 192);


  /* Typography */
  --font-family-base: 'Helvetica', sans-serif;
  --font-size-base: 16px; /* Base font size */
  --font-size-xl: 1.5rem; /* 20px if base is 16px */
  --font-size-l: 1.25rem; /* 20px if base is 16px */
  --font-size-m: 1rem; /* 20px if base is 16px */
  --font-size-s: .875rem; /* 14px if base is 16px */
  --line-height-base: 1.5;

  /* Spacing */
  --spacing-xxs: 0.2rem;
  --spacing-xs: 0.5rem;
  --spacing-s: 1rem;
  --spacing-m: 2rem;
  --spacing-l: 3rem;
  --spacing-xl: 4rem;

  /* Borders */
  --border-radius-s: 5px;
  --border-radius-m: 10px;
  --border-radius-l: 20px;

  /* Shadows */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
font-family: var(--font-family-base);
font-size: var(--font-size-base);
line-height: var(--line-height-base);
color: var(--text-color);
background-color: var(--background-color-black);
}

h1, h2, h3, h4, h5, h6 {
margin-top: 0;
color: var(--text-color);
}

a {
color: var(--link-color);
text-decoration: none;
}

button, input, select, textarea {
font: inherit;
color: inherit;
}

a, button, .text-button, span {
  transition: color 0.3s ease; /* Smooth transition for hover effect */
  color: var(--text-color);
}

*:not(:placeholder-shown) { /* This selector targets all elements except placeholders */
  border-color: var(--list-border-color); /* Standard border color */
  border-width: 0.1px; /* Standard border width */
}


/* Global hover style */
a:hover, button:hover, span:hover, li:hover, .custom-hover-class:hover {
  color: var(--hover-white); 
  
}



/* Utility classes for margins and paddings */
.mt-sm { margin-top: var(--spacing-sm); }
.mr-sm { margin-right: var(--spacing-sm); }
.mb-sm { margin-bottom: var(--spacing-sm); }
.ml-sm { margin-left: var(--spacing-sm); }

.pt-sm { padding-top: var(--spacing-sm); }
.pr-sm { padding-right: var(--spacing-sm); }
.pb-sm { padding-bottom: var(--spacing-sm); }
.pl-sm { padding-left: var(--spacing-sm); }