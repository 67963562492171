.artistPage {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
}

.artistNameContainer{
    display: flex;
    justify-content: left;
    background-color: var(--background-color);
    padding: 10px;
    border-radius: var(--border-radius-lg);
    margin-top: 0px;
}

.artistName {
    margin-bottom: 0;
    padding-left: 20px;
}


.songsSection, .similarArtistsSection {
    padding-inline: 30px;
    padding-block: 20px;
    margin: 10px 0  10px;
    background-color: var(--background-color);
    border-radius: var(--border-radius-lg);
}

.subheader {
    margin-bottom: 5px;
    text-align: left; /* Align to the left for subheaders */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .content {
        flex-direction: column;
        align-items: center;
    }
}